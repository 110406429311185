.aws-sld__container--3PF5 figure, .aws-sld__content--yN0F, .aws-sld__box--3PO_ {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0; }

.aws-sld__controls--3uxR button, .aws-sld__bullets--2Jyj button {
  outline-color: 0;
  outline-style: none;
  outline-width: 0; }

.aws-sld--1ase {
  --organic-arrow-thickness: 4px;
  --organic-arrow-height: 40px;
  --slider-height-percentage: 60%;
  --loader-bar-color: #851515;
  --loader-bar-height: 6px;
  --control-button-width: 10%;
  --control-button-height: 25%;
  --control-button-opacity: 0.5;
  --control-button-hover-opacity: 0.75;
  --control-button-background: transparent;
  --transition-bezier: cubic-bezier(0.5, 0.075, 0.25, 0.95);
  --slider-transition-duration: 575ms;
  --organic-arrow-color: #6a6a6a;
  --organic-arrow-border-radius: 0;
  --control-bullet-color: #6a6a6a;
  --control-bullet-active-color: #6a6a6a;
  --content-background-color: #2f2f2f; }

.aws-sld--1ase {
  display: block;
  position: relative;
  width: 100%;
  max-width: 100%; }
  .aws-sld__wrapper--qiLu {
    display: block;
    overflow: hidden;
    position: relative;
    height: 100%;
    width: 100%; }
  .aws-sld__container--3PF5 {
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: var(--slider-height-percentage); }
    @media all and (max-width: 500px) {
      .aws-sld__container--3PF5 {
        padding-bottom: calc(var(--slider-height-percentage) * 1.25); } }
  .aws-sld__startUp--1LXx {
    background-color: red;
    height: 100%;
    width: 100%; }
    .aws-sld__startUp--1LXx > div {
      height: 100%;
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center; }
    .aws-sld__startUp--1LXx img {
      width: 35%;
      height: auto; }
  .aws-sld__content--yN0F {
    background-color: var(--content-background-color);
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
    .aws-sld__content--yN0F > img,
    .aws-sld__content--yN0F > video {
      -o-object-fit: cover;
         object-fit: cover;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0; }
  .aws-sld__controls--3uxR button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: var(--control-button-width);
    height: var(--control-button-height);
    position: absolute;
    z-index: 10;
    top: calc(50% - (0.5 * var(--control-button-height)));
    border: none;
    background-color: var(--control-button-background);
    color: #fff;
    cursor: pointer; }
    .aws-sld__controls--3uxR button .aws-sld__controls__arrow-left--2qsQ,
    .aws-sld__controls--3uxR button .aws-sld__controls__arrow-right--1uYP {
      opacity: var(--control-button-opacity); }
    .aws-sld__controls--3uxR button:hover .aws-sld__controls__arrow-left--2qsQ,
    .aws-sld__controls--3uxR button:hover .aws-sld__controls__arrow-right--1uYP {
      opacity: var(--control-button-opacity-hover); }
  .aws-sld__controls--active--15A8 .aws-sld__controls__arrow-left--2qsQ {
    opacity: var(--control-button-opacity-hover);
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0); }
  .aws-sld__controls--active--15A8 .aws-sld__controls__arrow-right--1uYP {
    opacity: var(--control-button-opacity-hover);
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0); }
  @media all and (max-width: 520px) {
    .aws-sld__controls--3uxR {
      visibility: hidden; } }
  .aws-sld__bar--2GSZ {
    display: block;
    width: 100%;
    height: var(--loader-bar-height);
    background-color: var(--loader-bar-color);
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transition: -webkit-transform 3000ms var(--transition-bezier);
    transition: -webkit-transform 3000ms var(--transition-bezier);
    transition: transform 3000ms var(--transition-bezier);
    transition: transform 3000ms var(--transition-bezier), -webkit-transform 3000ms var(--transition-bezier);
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0); }
    .aws-sld__bar--active--aSFe {
      -webkit-transform: translate3d(-20%, 0, 0);
              transform: translate3d(-20%, 0, 0); }
    .aws-sld__bar--end--3QDQ {
      -webkit-transition-duration: 300ms;
              transition-duration: 300ms;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0); }
  .aws-sld__next--agPd {
    right: 0; }
  .aws-sld__prev--xzcr {
    left: 0; }
  .aws-sld__box--3PO_ {
    z-index: 1; }
  .aws-sld--animated--2ddM {
    will-change: transform;
    -webkit-transition: -webkit-transform var(--slider-transition-duration) var(--transition-bezier);
    transition: -webkit-transform var(--slider-transition-duration) var(--transition-bezier);
    transition: transform var(--slider-transition-duration) var(--transition-bezier);
    transition: transform var(--slider-transition-duration) var(--transition-bezier), -webkit-transform var(--slider-transition-duration) var(--transition-bezier); }
  .aws-sld--animated-mobile--3RYz {
    will-change: transform;
    -webkit-transition: -webkit-transform 325ms cubic-bezier(0.15, 0.65, 0.1, 1);
    transition: -webkit-transform 325ms cubic-bezier(0.15, 0.65, 0.1, 1);
    transition: transform 325ms cubic-bezier(0.15, 0.65, 0.1, 1);
    transition: transform 325ms cubic-bezier(0.15, 0.65, 0.1, 1), -webkit-transform 325ms cubic-bezier(0.15, 0.65, 0.1, 1); }
  .aws-sld--active--1qJr {
    z-index: 2;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  .aws-sld--moveRight--1I2m {
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0); }
  .aws-sld--moveLeft--2SB- {
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0); }
  .aws-sld--exit--3EKv {
    z-index: 0; }
  .aws-sld--first--j71- .aws-sld__prev--xzcr {
    visibility: hidden; }
  .aws-sld--last--3fQe .aws-sld__next--agPd {
    visibility: hidden; }
  .aws-sld--fill-parent--2nym {
    position: absolute !important;
    width: 100% !important;
    height: 100% !important;
    top: 0;
    left: 0; }
    .aws-sld--fill-parent--2nym .aws-sld__container--3PF5 {
      height: 100%;
      padding: 0; }
  .aws-sld__bullets--2Jyj {
    position: absolute;
    bottom: -40px;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
    .aws-sld__bullets--2Jyj button {
      padding: 0;
      display: block;
      width: 16px;
      height: 16px;
      margin: 5px;
      border-radius: 50%;
      background: var(--control-bullet-color);
      text-indent: -9999px;
      overflow: hidden;
      cursor: pointer;
      border: none;
      -webkit-transition: background-color 0.175s ease-out, -webkit-transform 0.225s cubic-bezier(0.8, 1.35, 0.75, 1.45);
      transition: background-color 0.175s ease-out, -webkit-transform 0.225s cubic-bezier(0.8, 1.35, 0.75, 1.45);
      transition: transform 0.225s cubic-bezier(0.8, 1.35, 0.75, 1.45), background-color 0.175s ease-out;
      transition: transform 0.225s cubic-bezier(0.8, 1.35, 0.75, 1.45), background-color 0.175s ease-out, -webkit-transform 0.225s cubic-bezier(0.8, 1.35, 0.75, 1.45); }
      .aws-sld__bullets--2Jyj button:hover {
        -webkit-transform: scale(1.2);
                transform: scale(1.2); }
    .aws-sld__bullets--2Jyj .aws-sld__bullets--loading--1Qzo {
      -webkit-transform: scale(1.2);
              transform: scale(1.2); }
    .aws-sld__bullets--2Jyj .aws-sld__bullets--active--3BY1 {
      -webkit-transform: scale(1.5);
              transform: scale(1.5);
      background: var(--control-bullet-active-color); }
      .aws-sld__bullets--2Jyj .aws-sld__bullets--active--3BY1:hover {
        -webkit-transform: scale(1.5);
                transform: scale(1.5); }
  .aws-sld__controls__arrow-left--2qsQ, .aws-sld__controls__arrow-right--1uYP {
    width: 100%;
    height: var(--organic-arrow-height);
    position: relative;
    display: block;
    -webkit-transition: opacity 0.2s ease-out, -webkit-transform 0.2s ease-out 0.125s;
    transition: opacity 0.2s ease-out, -webkit-transform 0.2s ease-out 0.125s;
    transition: transform 0.2s ease-out 0.125s, opacity 0.2s ease-out;
    transition: transform 0.2s ease-out 0.125s, opacity 0.2s ease-out, -webkit-transform 0.2s ease-out 0.125s; }
    .aws-sld__controls__arrow-left--2qsQ:before, .aws-sld__controls__arrow-left--2qsQ:after, .aws-sld__controls__arrow-right--1uYP:before, .aws-sld__controls__arrow-right--1uYP:after {
      content: ' ';
      position: absolute;
      right: calc( 50% - ( 0.7071 * (var(--organic-arrow-height) + var(--organic-arrow-thickness)) ) / 2);
      height: 100%;
      border-radius: var(--organic-arrow-border-radius);
      width: var(--organic-arrow-thickness);
      background-color: var(--organic-arrow-color);
      -webkit-transition: background-color 0.15s ease-out, -webkit-transform 0.15s ease-out;
      transition: background-color 0.15s ease-out, -webkit-transform 0.15s ease-out;
      transition: transform 0.15s ease-out, background-color 0.15s ease-out;
      transition: transform 0.15s ease-out, background-color 0.15s ease-out, -webkit-transform 0.15s ease-out; }
    .aws-sld__controls__arrow-left--2qsQ:before, .aws-sld__controls__arrow-right--1uYP:before {
      -webkit-transform-origin: 100% 100% 0;
              transform-origin: 100% 100% 0;
      top: -50%;
      -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg); }
    .aws-sld__controls__arrow-left--2qsQ:after, .aws-sld__controls__arrow-right--1uYP:after {
      -webkit-transform-origin: 100% 0% 0;
              transform-origin: 100% 0% 0;
      top: 50%;
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg); }
  .aws-sld__controls__arrow-right--active--3NRE {
    -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0); }
    .aws-sld__controls__arrow-right--active--3NRE:after {
      -webkit-transform: rotate(90deg) translate3d(50%, 0, 0) !important;
              transform: rotate(90deg) translate3d(50%, 0, 0) !important; }
    .aws-sld__controls__arrow-right--active--3NRE:before {
      -webkit-transform: rotate(-90deg) translate3d(50%, 0, 0) !important;
              transform: rotate(-90deg) translate3d(50%, 0, 0) !important; }
  .aws-sld__controls__arrow-left--2qsQ:before, .aws-sld__controls__arrow-left--2qsQ:after {
    right: auto;
    left: calc( 50% - ( 0.7071 * (var(--organic-arrow-height) + var(--organic-arrow-thickness)) ) / 2); }
  .aws-sld__controls__arrow-left--2qsQ:before {
    -webkit-transform-origin: 0 100% 0;
            transform-origin: 0 100% 0;
    top: -50%;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg); }
  .aws-sld__controls__arrow-left--2qsQ:after {
    -webkit-transform-origin: 0 0 0;
            transform-origin: 0 0 0;
    top: 50%;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg); }
  .aws-sld__controls__arrow-left--active--3BVR {
    -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0); }
    .aws-sld__controls__arrow-left--active--3BVR:after {
      -webkit-transform: rotate(-90deg) translate3d(-50%, 0, 0) !important;
              transform: rotate(-90deg) translate3d(-50%, 0, 0) !important; }
    .aws-sld__controls__arrow-left--active--3BVR:before {
      -webkit-transform: rotate(90deg) translate3d(-50%, 0, 0) !important;
              transform: rotate(90deg) translate3d(-50%, 0, 0) !important; }
  .aws-sld__controls--3uxR button:hover .aws-sld__controls__arrow-left--2qsQ:before {
    opacity: 1;
    -webkit-transform: rotate(30deg);
            transform: rotate(30deg); }
  .aws-sld__controls--3uxR button:hover .aws-sld__controls__arrow-left--2qsQ:after {
    opacity: 1;
    -webkit-transform: rotate(-30deg);
            transform: rotate(-30deg); }
  .aws-sld__controls--3uxR button:hover .aws-sld__controls__arrow-right--1uYP:before {
    opacity: 1;
    -webkit-transform: rotate(-30deg);
            transform: rotate(-30deg); }
  .aws-sld__controls--3uxR button:hover .aws-sld__controls__arrow-right--1uYP:after {
    opacity: 1;
    -webkit-transform: rotate(30deg);
            transform: rotate(30deg); }

